<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Modifier une Division",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  async mounted() {
    await this.fetchDirectionsList();
    this.divForm.uuid = this.$route.params.uid;
    this.fetchDivisionData();
  },
  data() {
    return {
      title: "Modifier une Division",
      items: [
        {
          text: "Hiérarchie",
          href: "/",
        },
        {
          text: "Divisions",
          href: "/",
        },
        {
          text: "Modification",
          active: true,
        },
      ],
      divForm: {
        divName: "",
        divAdr: "",
        divAc: "",
        divDirection: "",
        selectedDirection: "",
      },

      directions: [],

      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    divForm: {
      divName: { required, maxLength: maxLength(64), },
      divAdr: { required, maxLength: maxLength(64), },
      divAc: { required, maxLength: maxLength(64), },
      divDirection: { required }
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    fetchDirectionsList() {
      this.$http
        .post("/hierarchy/directions/list")
        .then((res) => (this.directions = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchDivisionData() {
      var _this = this;
      this.$http
        .post(
          "/hierarchy/divisions/getSingleDivisionDetails/" + this.divForm.uuid
        )

        .then((res) => {
          var status = res.data.original.status;
          var division = res.data.original.data.division;
          switch (status) {
            case 200:
              _this.divForm.divName = division.name;
              _this.divForm.divAdr = division.address;
              _this.divForm.divAc = division.analytic_account;
              _this.divForm.divDirection = division.direction_id;
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
        var router = this.$router;
        this.$http
          .post("/hierarchy/divisions/update", this.divForm)
          .then((res) => {
            //Perform Success Action
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                router.push({ name: "hierarchy.divisions.index" });
  
                break;
  
              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            // error.response.status Check status code
            this.$toast.error(error.message);
          })
          .finally(() => {
            //Perform action in always
          });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Désignation</label>
                    <input
                      id="deptName"
                      v-model="divForm.divName"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="Mark"
                      :class="{
                        'is-invalid': submitted && $v.divForm.divName.$error,
                      }"
                    />
                    <div v-if="submitted && $v.divForm.divName.$error" class="invalid-feedback">
                        <span v-if="!$v.divForm.divName.required" >Le nom est obligatoire.</span>
                        <span v-if="!$v.divForm.divName.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                      </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Addresse</label>
                    <input
                      id="validationCustom02"
                      v-model="divForm.divAdr"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="Otto"
                      :class="{
                        'is-invalid': submitted && $v.divForm.divAdr.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.divForm.divAdr.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.divForm.divAdr.required"
                        >L'addresse de la division est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="formrow-password-input">Direction</label>
                    <select
                      id="projectname"
                      v-model="divForm.divDirection"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.divForm.divDirection.$error,
                      }"
                      placeholder=""
                    >
                      <option value=""></option>
                      <option
                        v-for="direction in directions"
                        v-bind:value="direction.id"
                        v-bind:key="direction.id"
                      >
                        {{ direction.name }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.divForm.divDirection.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.divForm.divDirection.required"
                        >Le direction est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">Compte analytique</label>
                    <input
                      id="validationCustom03"
                      v-model="divForm.divAc"
                      type="text"
                      class="form-control"
                      placeholder=""
                      :class="{
                        'is-invalid': submitted && $v.divForm.divAc.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.divForm.divAc.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.divForm.divAc.required"
                        >Le compte analytique de la division est
                        obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-primary"
                type="submit"
              >
                Enregistrer
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
